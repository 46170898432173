import React from 'react';
import WikiSearchInput from 'components/Wiki/WikiSearchInput';
import WikiAutoCompleteInput from 'components/Wiki/WikiAutoCompleteInput';
import './wiki-search.scss';
import { Link } from 'react-router-dom';
import IosChatbubbles from 'react-ionicons/lib/IosChatbubbles';

const WikiSearchPanel = () => (
  <div className="wiki-header">
    <div className="wiki-search-container">
      <WikiSearchInput placeholderText="Search in text" />
      <WikiAutoCompleteInput placeholderText="Search in tags" />
    </div>
    <Link
      type="primary"
      to="/wiki/ai"
      className="wiki-ask-ai-link"
    >
      <IosChatbubbles />
        Ask the AI!
    </Link>
  </div>
);

export default WikiSearchPanel;
