import React, { useRef, Children, useEffect, useState } from 'react';
import { Button, Input, Spin } from 'antd';
import IosSend from 'react-ionicons/lib/IosSend';
import axios from 'axios';
import { CompanyLogo, getPageTitle } from '../../utils/branding';
import './WikiAiPage.scss';

export const WikiAiPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [question, setQuestion] = useState('');
  const [sessionId, setSessionId] = useState(null);

  const containerRef = useRef(null);

  const onSubmit = async () => {
    if (question === '') return;
    setIsLoading(true);
    setConversation((prev) => ([...prev, { type: 'question', sender: 'user', message: question }]));
    setQuestion('');
    try {
      const response = await axios.post(
        'https://0lg6fnxov8.execute-api.eu-central-1.amazonaws.com/staging',
        { question, sessionId },
        { headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        } },
      );
      const { answer, sessionId: resSessionId } = response.data;
      setSessionId(resSessionId);
      setConversation((prev) => [...prev, { type: 'answer', sender: 'ai', message: answer }]);
    } catch (err) {
      console.error(err);
      setConversation((prev) => [...prev, { type: 'error', sender: 'ai', message: 'An error occurred. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = getPageTitle('Wiki AI');
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [conversation]);

  return (
    <div className="wiki-ai__conversation-container">
      <CompanyLogo className="wiki-ai__company-logo" />
      <div className="wiki-ai__messages-container" ref={containerRef}>
        {Children.toArray(conversation.map((msg) => <div className={`wiki-ai__message ${msg.type}`}>{msg.message}</div>))}
        {isLoading && <div className="wiki-ai__message"><Spin /></div>}
      </div>
      <form
        className="wiki-ai__message-input"
        onSubmit={(e) => {
          e.preventDefault();
          // eslint-disable-next-line no-void
          void onSubmit();
        }}
      >
        <Input
          disabled={isLoading}
          value={question}
          name="message"
          className="wiki-ai__input"
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a company related thing..."
        />
        <Button
          className="wiki-ai__send-message-button"
          type="submit"
          disabled={isLoading}
          onClick={onSubmit}
        >
          <IosSend />
        </Button>
      </form>
    </div>
  );
};
